import React from 'react'
import PropTypes from 'prop-types'

function BillingIcon({ className, fill, height, width }) {
  return (
    <svg fill={fill} height={height} width={width} className ={className} xmlns='http://www.w3.org/2000/svg'  version='1.1' x='0px' y='0px' viewBox='0 0 64 64' enableBackground='new 0 0 64 64' ><g><g><path d='M8,12v48c0,0.53,0.211,1.039,0.586,1.414l2,2c0.781,0.781,2.047,0.781,2.828,0L16,60.829l2.586,2.585    c0.781,0.781,2.047,0.781,2.828,0L24,60.829l2.586,2.585c0.781,0.781,2.047,0.781,2.828,0L32,60.829l2.586,2.585    c0.781,0.781,2.047,0.781,2.828,0L40,60.829l2.586,2.585c0.781,0.781,2.047,0.781,2.828,0L48,60.829l2.586,2.585    C50.977,63.805,51.488,64,52,64s1.023-0.195,1.414-0.586l2-2C55.789,61.039,56,60.53,56,60V12H8z M34,50c0,1.104-0.896,2-2,2    s-2-0.896-2-2h-2c-1.104,0-2-0.896-2-2s0.896-2,2-2h6c1.103,0,2-0.897,2-2s-0.897-2-2-2h-4c-3.309,0-6-2.691-6-6s2.691-6,6-6    c0-1.104,0.896-2,2-2s2,0.896,2,2h2c1.104,0,2,0.896,2,2s-0.896,2-2,2h-6c-1.103,0-2,0.897-2,2s0.897,2,2,2h4c3.309,0,6,2.691,6,6    S37.309,50,34,50z M44,22H20c-1.104,0-2-0.896-2-2s0.896-2,2-2h24c1.104,0,2,0.896,2,2S45.104,22,44,22z'></path><path d='M58,0H6C4.896,0,4,0.896,4,2v6c0,1.104,0.896,2,2,2h52c1.104,0,2-0.896,2-2V2C60,0.896,59.104,0,58,0z'></path></g></g></svg>
  )
}

BillingIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

BillingIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default BillingIcon