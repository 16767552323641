import jwt from 'jwt-decode'

export default function checkToken (existingTokens) {
  const decodedToken = jwt(existingTokens)
  const now = new Date().getTime() / 1000

  if (now > decodedToken.exp) {
    localStorage.removeItem('tokens');
    localStorage.setItem('tokenStatus', JSON.stringify(false))
    return false
  } else {
    localStorage.setItem('tokenStatus', JSON.stringify(true))
    return true
  } 
}


