import React from 'react'
import {Route} from 'react-router-dom'
import checkToken from './Functions/checkToken'
import {Redirect} from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const existingTokens = JSON.parse(localStorage.getItem('tokens'))
    let hasToken = false
    if (existingTokens !== null) {
        hasToken = checkToken(existingTokens)
    }
    
    return(
        <Route {...rest} render={props => (
            hasToken ?
                <Component {...props} />
            : <Redirect to='/login' />
        )} />
    );  
}

export default PrivateRoute