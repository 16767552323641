import React from 'react'
import PropTypes from 'prop-types'

function MonitorIcon({ height, width, fill, className }) {
  return (
      <svg fill={fill} height={height} width={width} className={className} version="1.1" viewBox="0 0 128 128">
        <g>
          <path d="m105 34h-82c-3.3008 0-6 2.6992-6 6v46c0 3.3008 2.6992 6 6 6h82c3.3008 0 6-2.6992 6-6v-46c0-3.3008-2.6992-6-6-6zm-4.7617 45.238c0 1.1055-0.89453 2-2 2h-68.477c-1.1055 0-2-0.89453-2-2v-32.477c0-1.1055 0.89453-2 2-2h68.48c1.1055 0 2 0.89453 2 2z"/>
          <path d="m75.238 95h-2.9805l-0.27734-1h-15.961l-0.28125 1h-2.9805c-2.0391 0-4 0.98047-5.1992 2.6016-1.5 1.9805-1.7383 4.5781-0.62109 6.8008 1.1016 2.2188 3.3438 3.5977 5.8242 3.5977h22.48c2.4805 0 4.7188-1.3789 5.8203-3.6016 1.1211-2.2188 0.87891-4.8203-0.60156-6.8008-1.2227-1.6172-3.1797-2.5977-5.2227-2.5977z"/>
        </g>
      </svg>
  )
}

MonitorIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string
}

MonitorIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
  className: undefined
}

export default MonitorIcon


