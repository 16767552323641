import React from 'react'
import PropTypes from 'prop-types'

function ScreenIcon({ height, width, fill }) {
  return (
    <svg height={height} width={width} fill={fill} version="1.1" x="0px" y="0px" viewBox="0 0 1200 1200" >
      <g>
        <path d="m1150 72.25c-181.98 28.102-365.86 42.227-550 42.25-184.14-0.10938-368-14.234-550-42.25l-50-7.75v760.5l50-7.75c364.5-56.41 735.5-56.41 1100 0l50 7.75v-760.5zm-36.25 650c-340.01-47.621-684.99-47.621-1025 0v-555c169.39 23.699 340.21 35.645 511.25 35.75 171.46-0.027344 342.7-11.973 512.5-35.75z"/>
        <path d="m304 989.25c-14.113 12.16-32.121 18.848-50.75 18.848s-36.637-6.6875-50.75-18.848c-26.062 14.336-47.965 35.184-63.562 60.508-15.598 25.328-24.363 54.266-25.438 83.992h279.75c-1.2188-29.723-10.07-58.629-25.703-83.938s-37.516-46.164-63.547-60.562z"/>
        <path d="m253.25 996.5c24.645 0.23047 47.949-11.199 62.852-30.824 14.906-19.629 19.656-45.148 12.816-68.824-6.8398-23.676-24.473-42.727-47.547-51.383-23.078-8.6523-48.887-5.8906-69.609 7.4492-20.723 13.34-33.922 35.691-35.598 60.277-1.6758 24.59 8.3672 48.523 27.086 64.555 13.918 11.977 31.637 18.621 50 18.75z"/>
        <path d="m647.25 991.25c-14.105 12.273-32.176 19.035-50.875 19.035s-36.77-6.7617-50.875-19.035c-26.242 14.031-48.289 34.777-63.883 60.121-15.594 25.348-24.176 54.379-24.867 84.129h279.75c-0.74219-29.801-9.3984-58.867-25.082-84.215-15.684-25.352-37.832-46.07-64.168-60.035z"/>
        <path d="m596.5 1e3c20.672-0.066406 40.473-8.3359 55.051-22.992s22.742-34.5 22.699-55.176c-0.042969-20.672-8.293-40.48-22.934-55.074s-34.477-22.781-55.148-22.758-40.492 8.25-55.102 22.875-22.816 34.453-22.816 55.125c0 20.73 8.2539 40.609 22.934 55.242 14.684 14.637 34.586 22.824 55.316 22.758z"/>
        <path d="m998.25 991.25c-13.918 12.012-31.617 18.738-50 19-18.402-0.15625-36.141-6.8945-50-19-26.238 14.059-48.293 34.805-63.926 60.141-15.629 25.332-24.281 54.352-25.074 84.109h278c-0.74219-29.77-9.3711-58.809-25.008-84.152-15.637-25.34-37.719-46.082-63.992-60.098z"/>
        <path d="m947.25 1e3c24.645 0.23047 47.949-11.199 62.852-30.824 14.906-19.629 19.656-45.148 12.816-68.824-6.8398-23.676-24.473-42.727-47.547-51.383-23.078-8.6523-48.887-5.8906-69.609 7.4492-20.723 13.34-33.922 35.691-35.598 60.277-1.6758 24.59 8.3672 48.523 27.086 64.555 13.898 12.016 31.629 18.664 50 18.75z"/>
      </g>
    </svg>
    
  )
}

ScreenIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

ScreenIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default ScreenIcon


