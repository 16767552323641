import React, { Fragment } from 'react'
import FxIcon from '../Icons/FxIcon'

const FxNavIcon = (props) => {
  return(
    <Fragment>
      <span className={props.width < 480 ? 'small-navFont' : 'navFont'} style={{color:'#d8d8de'}}>
        <span style={{paddingRight:'.25em'}}>
          <FxIcon 
            height={
              props.width < 480 ? '.8em' : '1.3em'
            } 
            width={
              props.width < 480 ? '.8em' : '1.3em'
            } 
            fill='#d8d8de'
          />
        </span>
      </span> 
    </Fragment>
  )
}

export default FxNavIcon