import React from 'react'
import PropTypes from 'prop-types'

function CinemaIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} width={width} height={height} className ={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' x='0px' y='0px'>
      <g><polygon points='33 54 35 54 35 56 33 56 33 60 40 60 40 50 33 50 33 54'></polygon><rect x='24' y='44' width='16' height='4'></rect><polygon points='24 60 31 60 31 56 29 56 29 54 31 54 31 50 24 50 24 60'></polygon><rect x='27' y='32' width='4' height='4'></rect><path d='M4,60H22V44H4ZM14,46h6V58H14ZM6,46h6V58H6Z'></path><rect x='27' y='26' width='4' height='4'></rect><rect x='8' y='48' width='2' height='8'></rect><polygon points='47 20.721 43 19.388 43 42 47 42 47 20.721'></polygon><polygon points='54 39 51 39 51 37 54 37 54 28 52 28 52 26 54 26 54 23 49 23 49 30 51 30 51 32 49 32 49 42 54 42 54 39'></polygon><polygon points='21 16.388 17 17.721 17 42 21 42 21 16.388'></polygon><rect x='16' y='48' width='2' height='8'></rect><polygon points='15 32 13 32 13 30 15 30 15 23 10 23 10 26 12 26 12 28 10 28 10 37 13 37 13 39 10 39 10 42 15 42 15 32'></polygon><rect x='27' y='14' width='4' height='4'></rect><polygon points='8 19.721 4 18.388 4 42 8 42 8 19.721'></polygon><rect x='27' y='20' width='4' height='4'></rect><path d='M32,4a9.01,9.01,0,0,0-9,9V42h1.586l2.707-2.707,1.414,1.414L27.414,42h2.172l2.707-2.707,1.414,1.414L32.414,42h2.172l2.707-2.707,1.414,1.414L37.414,42H41V13A9.01,9.01,0,0,0,32,4Zm7,33a1,1,0,0,1-1,1H26a1,1,0,0,1-1-1V13a7,7,0,0,1,14,0Z'></path><rect x='46' y='48' width='2' height='8'></rect><rect x='54' y='48' width='2' height='8'></rect><polygon points='56 17.721 56 42 60 42 60 16.388 56 17.721'></polygon><path d='M42,60H60V44H42ZM52,46h6V58H52Zm-8,0h6V58H44Z'></path><rect x='33' y='32' width='4' height='4'></rect><rect x='33' y='26' width='4' height='4'></rect><path d='M27.1,12H31V8.1A5.016,5.016,0,0,0,27.1,12Z'></path><path d='M33,8.1V12h3.9A5.016,5.016,0,0,0,33,8.1Z'></path><rect x='33' y='20' width='4' height='4'></rect><rect x='33' y='14' width='4' height='4'></rect></g>
    </svg>  
  )
}

CinemaIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

CinemaIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '1em',
  width: '1em'
}

export default CinemaIcon