import React from 'react'
import PropTypes from 'prop-types'

function EmailIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><g transform="translate(0,-952.36218)"><path d="M 11.96875,974.36217 50,1009.6122 l 38.03125,-35.25003 -76.062501,0 z m -2.9687501,2.75 0,50.40623 L 35.15625,1001.3622 8.9999999,977.11217 z M 91,977.11217 64.84375,1001.3622 91,1027.5184 91,977.11217 z m -52.90625,26.96873 -26.281251,26.2813 76.375001,0 -26.28125,-26.2813 -10.53125,9.75 a 2.0002,2.0002 0 0 1 -2.75,0 l -10.53125,-9.75 z" fill={fill} stroke="none" marker="none" visibility="visible" display="inline" overflow="visible"></path></g></svg>
  )
}



EmailIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

EmailIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default EmailIcon


