import React from 'react'
import PropTypes from 'prop-types'

function RepairIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.63 70.75" x="0px" y="0px"><path d="M320.46,631.33L304,638.65l-3.7-8.31a1.64,1.64,0,0,1,.83-2.16l15-6.65a1.16,1.16,0,0,0,.48-1.95c-6.18-4.62-12.17-6.39-22.3-3.66-10.69,2.88-9.38,20.35-11.76,28.08-2.57,8.33-13.47,17.76-20.1,22.85a10.3,10.3,0,0,0-.17,16.51A10.5,10.5,0,0,0,278,679.29c6-11.87,6.87-19.26,14.73-21.41,5.64-1.54,12.88,2.05,18.4.73a1.5,1.5,0,0,0,.85-2.11l-2.23-5a1.9,1.9,0,0,0,.3-0.19c7-6.28,10.81-10.62,11.79-19A0.88,0.88,0,0,0,320.46,631.33Zm-47.64,47a5.17,5.17,0,1,1-1.14-7.22A5.17,5.17,0,0,1,272.82,678.37Zm26.45-29.86-2.18,1a4.25,4.25,0,0,1-5.62-2.16L289,641.74a4.25,4.25,0,0,1,2.15-5.6l2.17-1a4.25,4.25,0,0,1,5.62,2.16l2.47,5.58A4.25,4.25,0,0,1,299.26,648.51Z" transform="translate(-258.18 -614.63)"></path><rect x="288.51" y="638.39" width="10.33" height="1.17" transform="translate(-491.1 -441.74) rotate(-23.8)"></rect><rect x="289.53" y="640.62" width="10.33" height="1.17" transform="translate(-491.92 -441.13) rotate(-23.8)"></rect><rect x="290.55" y="642.86" width="10.33" height="1.17" transform="translate(-492.74 -440.53) rotate(-23.81)"></rect><rect x="291.57" y="645.09" width="10.33" height="1.17" transform="translate(-493.01 -440.54) rotate(-23.74)"></rect></svg>
  )
}



RepairIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

RepairIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default RepairIcon


