import React, { Fragment } from 'react'
import ScreenIcon from '../Icons/ScreenIcon'

const DoanNavLogo = (props) => {
  return(
    <Fragment>
      <span className={props.width < 480 ? 'small-navFont' : 'navFont'} style={{color:'#d8d8de'}}>
        <span style={{paddingRight:'.25em'}}>
          {(window.location.pathname !== '/' || (window.location.pathname === '/' && props.width < 992)) && 
            <ScreenIcon 
              height={
                props.width < 480 ? '.8em' : '1.1em'
              } 
              width={
                props.width < 480 ? '.8em' : '1.1em'
              } 
              fill='#FFA500'/>
          }
        </span>Doan
      </span> 
    </Fragment>
  )
}

export default DoanNavLogo