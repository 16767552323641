import React from 'react'
import PropTypes from 'prop-types'

function LicenseIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m596.14 305.46c-2.4766 0.39062-4.8711 1.1914-7.0859 2.3633l-332.52 166.26c-4.9844 2.5469-8.8516 6.8477-10.855 12.074-2.0039 5.2266-2.0039 11.008 0 16.234 2.0039 5.2266 5.8711 9.5273 10.855 12.074l332.52 166.26c6.3906 3.1992 13.918 3.1992 20.309 0l332.52-166.26c4.9844-2.5469 8.8516-6.8477 10.855-12.074 2.0039-5.2266 2.0039-11.008 0-16.234-2.0039-5.2266-5.8711-9.5273-10.855-12.074l-332.52-166.26c-4.0664-2.1055-8.6797-2.9297-13.223-2.3633zm3.0703 47.703 281.98 141.22-281.98 140.75-281.98-140.75zm330.86 223.64v0.003906c-3.0117 0.44141-5.9023 1.4844-8.5039 3.0703l-322.36 161.06-322.36-161.06h0.003907c-3.3477-1.7578-7.0859-2.6523-10.867-2.5977-6.793 0.18359-13.145 3.4102-17.309 8.7852-4.1602 5.375-5.6875 12.336-4.1641 18.961 1.5234 6.6211 5.9375 12.215 12.027 15.234l332.52 166.26c6.3906 3.1992 13.918 3.1992 20.309 0l332.52-166.26c6.9219-2.7969 12.023-8.8203 13.641-16.105 1.6211-7.2852-0.44922-14.902-5.5352-20.363-5.082-5.4648-12.531-8.0781-19.914-6.9844zm0 105.8c-3.0117 0.44531-5.9023 1.4883-8.5039 3.0703l-322.36 161.06-322.36-161.06h0.003907c-3.3477-1.7578-7.0859-2.6484-10.867-2.5977-6.793 0.1875-13.148 3.4141-17.309 8.7891-4.1602 5.375-5.6875 12.332-4.1641 18.957s5.9375 12.219 12.027 15.238l332.52 166.26v-0.003906c6.3906 3.2031 13.918 3.2031 20.309 0l332.52-166.26v0.003906c6.9219-2.7969 12.023-8.8203 13.645-16.105 1.6172-7.2852-0.45312-14.902-5.5352-20.367-5.0859-5.4648-12.535-8.0742-19.918-6.9844z"/>
    </svg>
    )
}

LicenseIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

LicenseIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default LicenseIcon


