import React from 'react'
import PropTypes from 'prop-types'

function ImageIcon({ height, width, fill }) {
  return (
      <svg fill={fill} height={height} width={width} version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><g><g><path d="M84.4,11.5H15.6c-7.2,0-13.1,5.9-13.1,13.1v50.8c0,7.2,5.9,13.1,13.1,13.1h68.8c7.2,0,13.1-5.9,13.1-13.1V24.6    C97.5,17.4,91.6,11.5,84.4,11.5z M67.4,27.9c4.3,0,7.8,3.5,7.8,7.8c0,4.3-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8    C59.5,31.4,63,27.9,67.4,27.9z M76.3,73.5H22.2c-2.7,0-4.4-3-2.9-5.3L38,39.4c1.4-2.1,4.5-2.1,5.8,0.1l12.1,19.9    c0.5,0.9,1.7,1,2.4,0.3l4.2-4c1.4-1.3,3.5-1.3,4.9,0.1l11.4,12C80.9,69.9,79.4,73.5,76.3,73.5z"></path></g></g></svg>
  )
}

ImageIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

ImageIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default ImageIcon


