import React from 'react'
import PropTypes from 'prop-types'

function DealerIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className = {className} width={width} height={height} xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 64 64' enableBackground='new 0 0 64 64'><g><g><path d='M32,25c4.963,0,9-4.038,9-9s-4.037-9-9-9c-4.962,0-9,4.038-9,9S27.038,25,32,25z'></path><path d='M48,29H16c-2.757,0-5,2.243-5,5v22c0,0.553,0.448,1,1,1h7V44c0-0.553,0.448-1,1-1s1,0.447,1,1v13h22V44    c0-0.553,0.447-1,1-1s1,0.447,1,1v13h7c0.553,0,1-0.447,1-1V34C53,31.243,50.757,29,48,29z M33,50c0,0.553-0.448,1-1,1    s-1-0.447-1-1v-6c0-0.553,0.448-1,1-1s1,0.447,1,1V50z M33,38c0,0.553-0.448,1-1,1s-1-0.447-1-1v-2c0-0.553,0.448-1,1-1    s1,0.447,1,1V38z M40,41h-2c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S40.553,41,40,41z'></path></g></g></svg>  
  )
}

DealerIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

DealerIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default DealerIcon