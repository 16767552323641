import React from 'react'
import PropTypes from 'prop-types'

function ProviderIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} version="1.1" viewBox="0 0 1200 1200" >
      <g>
      <path d="m1056 126h-351.6c-38.398 0-69.602 31.199-69.602 69.602v300c0 38.398 31.199 69.602 69.602 69.602l351.6-0.003906c38.398 0 69.602-31.199 69.602-69.602v-300c0-38.398-31.203-69.598-69.602-69.598zm-25.199 240-84 78c-6 4.8008-12 7.1992-19.199 7.1992-7.1992 0-15.602-3.6016-20.398-9.6016-10.801-12-9.6016-30 1.1992-40.801l30-27.602-188.4 0.003907c-15.602 0-28.801-13.199-28.801-28.801 0-15.602 13.199-28.801 28.801-28.801h188.4l-30-27.602c-12-10.801-12-28.801-1.1992-40.801s28.801-12 40.801-1.1992l84 78c6 6 9.6016 13.199 9.6016 20.398-1.2031 8.4062-4.8008 16.805-10.801 21.605z"/>
      <path d="m867.6 692.4c-68.398 15.602-190.8 31.199-234 18-18-8.3984-62.398-57.602-86.398-84-22.801-25.199-44.398-48-64.801-67.199-34.801-34.801-62.398-58.801-112.8-72-61.199-15.602-214.8-33.602-258 68.398-45.602 106.8-38.398 385.2-36 583.2 0 16.801 10.801 31.199 28.801 31.199h327.6c19.199 0 27.602-10.801 28.801-26.398 1.1992-10.801 12-199.2 19.199-333.6 31.199 30 62.398 55.199 97.199 66 32.398 10.801 70.801 14.398 110.4 14.398 93.602 0 192-20.398 217.2-26.398 46.801-10.801 76.801-57.602 66-104.4-9.6016-47.996-56.402-77.996-103.2-67.199z"/>
      <path d="m500.4 229.2c0 110.02-89.184 199.2-199.2 199.2s-199.2-89.184-199.2-199.2 89.184-199.2 199.2-199.2 199.2 89.184 199.2 199.2"/>
      </g>
    </svg>
  )
}



ProviderIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

ProviderIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default ProviderIcon