import React from 'react'
import PropTypes from 'prop-types'

function OrderIcon({ height, width, className, fill }) {
  return (
    <svg fill={fill} height={height} width={width} className = {className} xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 96 96' enableBackground='new 0 0 96 96' ><g><path d='M43.637,46.486c1.058,0.216,2.156-0.99,2.902-2.22L19.682,9.201l-6.994,5.355l26.368,34.431   C39.977,47.698,41.59,46.08,43.637,46.486z'></path><path d='M43.244,48.446c-1.114-0.228-2.2,1.164-2.742,1.982l10.168,6.477l-3.261-10.418C46.42,47.686,44.984,48.798,43.244,48.446z   '></path><path d='M35.901,13.332v13.809L48.688,43.88l5.529,17.652L38.191,51.329l0.063-0.1l-2.353-3.072v38.642h47.411V13.332H35.901z    M76.607,82.799H42.606v-1h34.001V82.799z M76.607,70.799H42.606v-1h34.001V70.799z M76.607,58.799H59.066v-1h17.541V58.799z    M76.607,46.798H55.066v-1h21.541V46.798z M76.607,34.798H46.606v-1h30.001V34.798z M76.607,22.798H42.606v-1h34.001V22.798z'></path></g></svg>
  )
}

OrderIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
}

OrderIcon.defaultProps = {
  width: '2em',
  height: '2em',
  className: undefined,
  fill: '#000000',
}

export default OrderIcon