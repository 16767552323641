import React from 'react'
import PropTypes from 'prop-types'

function SolutionIcon({ height, width, fill }) {
  return (
    <svg height={height} width={width} fill={fill} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" version="1.1" viewBox="0 0 846.66 846.66" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd"><g><path d="M104.89 166.89l636.88 0 0 383.7 -636.88 0 0 -383.7zm232.96 428.07l-304.74 0 0 34.72c0,27.57 22.56,50.11 50.11,50.11l340.11 0 340.11 0c27.56,0 50.12,-22.54 50.12,-50.11l0 -34.72 -304.75 0 0 43.06 -170.96 0 0 -43.06zm32.18 -296.14l35.48 0 0 11.59c0,22.68 35.65,22.68 35.65,0l0 -11.59 35.48 0 0 37.8 11.59 0c22.68,0 22.68,35.65 0,35.65l-11.59 0 0 36.81 -35.48 0 0 -11.59c0,-22.69 -35.65,-22.69 -35.65,0l0 11.59 -35.48 0 0 -36.81 -11.59 0c-22.68,0 -22.68,-35.65 0,-35.65l11.59 0 0 -37.8zm40.89 161.64l24.83 0 0 49.1 -24.83 0 0 -49.1zm78.95 -22.42l17.56 -17.55 34.72 34.72 -17.55 17.55 -34.73 -34.72zm39.98 -71.68l0 -24.82 49.1 0 0 24.82 -49.1 0zm-22.42 -78.95l-17.56 -17.56 34.73 -34.72 17.55 17.56 -34.72 34.72zm-71.68 -39.98l-24.83 0 0 -49.1 24.83 0 0 49.1zm-78.95 22.42l-17.56 17.56 -34.72 -34.72 17.56 -17.56 34.72 34.72zm-39.98 71.69l0 24.82 -49.1 0 0 -24.82 49.1 0zm22.42 78.95l17.56 17.55 -34.72 34.72 -17.56 -17.55 34.72 -34.72z"></path></g></svg>
  )
}



SolutionIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

SolutionIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default SolutionIcon