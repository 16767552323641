import React from 'react'
import PropTypes from 'prop-types'

function AuditIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className = {className} width={width} height={height} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m363.6 178.8c-154.8 154.8-177.6 392.4-67.199 571.2l-222 222c-43.199 43.199-43.199 111.6 0 154.8 43.199 43.199 111.6 43.199 154.8 0l220.8-220.8c178.8 109.2 416.4 87.602 571.2-67.199 181.2-181.2 181.2-476.4 0-658.8-181.2-183.6-476.4-183.6-657.6-1.1992zm522 522c-106.8 106.8-280.8 106.8-387.6 0s-106.8-280.8 0-387.6 280.8-106.8 387.6 0c106.8 108 106.8 280.8 0 387.6z"/>
    </svg>
  )
}

AuditIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

AuditIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default AuditIcon