import React from 'react'
import PropTypes from 'prop-types'

function TermsIcon({ height, width, fill, className }) {
  return (
     <svg fill={fill} height={height} width={width} className={className} xmlSpace="preserve" version="1.1"  viewBox="0 0 587 624" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd">
      <g>
        <path d="M199 304c-15,41 -54,70 -100,70 -46,0 -84,-29 -99,-70l23 0 67 -152c3,-8 16,-8 19,0l67 152 23 0 0 0 0 0zm189 0c14,41 53,70 99,70 46,0 85,-29 100,-70l-23 0 -67 -152c-3,-8 -16,-8 -19,0l-67 152 -23 0 0 0 0 0zm-95 320l145 0 0 -25c0,-13 -10,-24 -24,-24l-48 0c-2,-30 -21,-55 -49,-65l0 -354c14,-9 22,-25 20,-43 37,-41 88,-1 127,14 3,10 12,17 23,17 13,0 24,-11 24,-25 0,-13 -11,-24 -24,-24 -7,0 -13,3 -18,8 -46,-20 -95,-55 -143,-13 -5,-6 -12,-10 -19,-12 9,-5 15,-14 15,-25 0,-16 -28,-52 -29,-53 -1,1 -28,37 -28,53 0,11 6,20 15,25 -7,2 -14,6 -19,12 -48,-42 -97,-7 -144,13 -4,-5 -10,-8 -17,-8 -14,0 -25,11 -25,24 0,14 11,25 25,25 11,0 20,-7 23,-17 39,-15 90,-55 127,-14 -2,18 6,34 19,43l0 354c-27,10 -47,35 -48,65l-49 0c-13,0 -24,11 -24,24l0 25 145 0 0 0 0 0zm139 -320l55 -121 55 121 -110 0zm-277 0l-56 -121 -55 121 111 0z">
        </path>
      </g>
    </svg>
  )
}

TermsIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
}

TermsIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default TermsIcon


