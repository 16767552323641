import React from 'react'
import PropTypes from 'prop-types'

function AccessIcon({ height, width, fill, className }) {
  return (
    <svg width={width} height={height} fill={fill} className={className} version="1.1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
    <path d="m64 4.3008c-32.898 0-59.699 26.801-59.699 59.699s26.801 59.699 59.699 59.699 59.699-26.801 59.699-59.699-26.801-59.699-59.699-59.699zm-25 94.398-2.8008-2.8008 21.398-21.398-21.496-21.398 2.8008-2.8008 24.199 24.199zm52.898-23.801-2.8984 2.8008-24.199-24.199 24.199-24.199 2.8008 2.8008-21.301 21.398z"/>
   </svg>
  )
}

AccessIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string
}

AccessIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
  className: undefined
}

export default AccessIcon


