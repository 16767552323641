import React from 'react'
import PropTypes from 'prop-types'

function PhoneIcon({ height, width, fill }) {
  return (
    <svg fill={fill} height={height} width={width} version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><g><path d="M50,2.5C23.8,2.5,2.5,23.8,2.5,50c0,26.2,21.3,47.5,47.5,47.5S97.5,76.2,97.5,50C97.5,23.8,76.2,2.5,50,2.5z M76.1,67.6    c-0.9,2.2-2.5,4.4-3.5,5.4c-5.7,5.7-21,0.6-33.6-12C26.4,48.4,21.3,33.2,27,27.4c1-1,3.3-2.6,5.4-3.5c1.6-0.7,3.5-0.2,4.6,1.2    l6.3,8c1.3,1.6,1.1,4-0.5,5.4c-1.6,1.4-2.7,2.4-3,2.7c-2.4,2.4,1.1,7.5,6.4,12.8c5.3,5.3,10.3,8.9,12.8,6.4c0.3-0.3,1.3-1.4,2.7-3    c1.4-1.6,3.7-1.8,5.4-0.5l8,6.3C76.4,64,76.8,65.9,76.1,67.6z"></path></g></g></svg>
  )
}



PhoneIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

PhoneIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default PhoneIcon


