import React from 'react'
import Nav from 'react-bootstrap/Nav'
import MapIcon from '../Icons/MapIcon'
import getUser from '../Functions/getUser'

const MapNav = (props) => {
  
  let user = {role:3, user:'none'}
  
  if (props.token) {
    user = getUser()
  }

  let isTech = false
  if (user.role) {
    user.role === 1 || user.role === 2 || user.role === 8 ? isTech = true : isTech = false
  }
 
  return isTech ? 
    <Nav.Item className='d-lg-none alignment'><MapIcon height='1.5em' width='1.5em' fill='#FFA500'></MapIcon><Nav.Link style={{color:'#d8d8de'}} href='/locations'>&ensp;Locations Map</Nav.Link></Nav.Item> :
    null
}

export default MapNav