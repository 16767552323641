import React from 'react'
import PropTypes from 'prop-types'

function AudioIcon({ height, width, fill }) {
  return (
     <svg fill={fill} height={height} width={width}  version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><g><g><path d="M72.7,32c-1.9-1.8-4.8-1.7-6.6,0.1c-1.8,1.9-1.7,4.8,0.1,6.6c3.1,3,4.8,6.9,4.8,11.2c0,4.3-1.7,8.2-4.8,11.2     c-1.9,1.8-1.9,4.8-0.1,6.6c0.9,1,2.2,1.4,3.4,1.4c1.2,0,2.3-0.4,3.3-1.3c4.9-4.7,7.6-11.1,7.6-18C80.3,43.2,77.6,36.8,72.7,32z"></path><path d="M83.4,18.6c-1.9-1.7-4.9-1.6-6.6,0.4c-1.7,1.9-1.6,4.9,0.4,6.6c6.9,6.2,10.9,15.1,10.9,24.4c0,9.3-4,18.2-10.9,24.4     c-1.9,1.7-2.1,4.7-0.4,6.6c0.9,1,2.2,1.6,3.5,1.6c1.1,0,2.2-0.4,3.1-1.2c8.9-8,14-19.4,14-31.4C97.5,38,92.4,26.6,83.4,18.6z"></path><path d="M50.5,12.8L22.2,33.9H5.3c-1.6,0-2.8,1.3-2.8,2.8v26.5c0,1.6,1.3,2.8,2.8,2.8h16.9l28.3,21.2c1.9,1.4,4.5,0.1,4.5-2.3V15     C55,12.7,52.3,11.4,50.5,12.8z"></path></g></g></g></svg>
  )
}

AudioIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
}

AudioIcon.defaultProps = {
  width: '2em',
  height: '2em',
  fill: '#000000',
}

export default AudioIcon


