import React from 'react'
import PropTypes from 'prop-types'

function KeyIcon({ height, width, fill, className }) {
  return (
    <svg fill={fill} height={height} width={width} className={className} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m1120.8 974.4-476.4-477.6c25.199-45.602 39.602-97.199 39.602-152.4 0-172.8-141.6-314.4-315.6-314.4s-314.4 141.6-314.4 314.4c0 172.8 140.4 315.6 314.4 315.6 55.199 0 106.8-14.398 152.4-39.602l273.6 273.6-73.199 73.199c-20.398 20.398-20.398 51.602 0 72l115.2 115.2c20.398 20.398 51.602 20.398 72 0l73.199-73.199 15.602 15.602c16.801 16.801 39.602 25.199 61.199 25.199 21.602 0 44.398-8.3984 61.199-25.199 34.801-33.602 34.801-88.801 1.2031-122.4zm-892.8-630c0-76.797 62.398-140.4 140.4-140.4s140.4 63.602 140.4 140.4c0 78-63.602 140.4-140.4 140.4-78 1.2031-140.4-62.398-140.4-140.4z"/>
    </svg>
  )
}

KeyIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

KeyIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default KeyIcon


